import { AdminSidebarService } from './admin-sidebar.service'
import { Component, OnInit } from '@angular/core'
import { trigger, state, style, transition, animate } from '@angular/animations'
import { ApiService } from 'src/app/services/api.service'
import { Menu } from 'src/app/interfaces/menu'
import { ThemeService } from 'src/app/services/theme.service'
import { Subject } from 'rxjs'
@Component({
    selector: 'app-admin-sidebar',
    templateUrl: './admin-sidebar.component.html',
    styleUrls: ['./admin-sidebar.component.css'],
    animations: [
        trigger('slide', [
            state('up', style({ height: 0 })),
            state('down', style({ height: '*' })),
            transition('up <=> down', animate(200))
        ])
    ]
})
export class AdminSidebarComponent implements OnInit {
    subMenuState = ''
    menuState = ''
    menus: Array<Menu> = []
    user: any
    activeState = 'Dashboard'
    isCollapsed = true
    searchKeyword = ''
    notification = 0

    socket: any = ''
    SOCKET_ENDPOINT: any = `https://python-api.unitedcheerleading.com`
    //SOCKET_ENDPOINT: any = `localhost:3014`

    searchKeyword$: Subject<string> = new Subject<string>()
    searchKeywordSub: any
    constructor(
        public sidebarservice: AdminSidebarService,
        private api: ApiService,
        private themeService: ThemeService
    ) {
        this.user = this.api.user
        this.menus = sidebarservice.getMenuList()

        /* this.sidebarservice.getNotificationCount().subscribe(r => {
             if (r.success === true) {
                 this.api.notification.next(r.data)
             }
         })*/
    }

    getSideBarState() {
        return this.sidebarservice.getSidebarState()
    }

    toggleSubMenu(parentMenu: Menu, subMenu: Menu) {
        if (subMenu.type === 'dropdown') {
            parentMenu.submenus?.forEach((element) => {
                if (element === subMenu) {
                    subMenu.active = !subMenu.active
                } else {
                    element.active = false
                }
            })
        }
    }

    ngOnInit(): void {
        this.api.notification.subscribe(r => {
            this.notification = +r
        })
    }


    getState(currentMenu: Menu) {
        if (currentMenu.active) {
            return 'down'
        }
        return 'up'
    }

    hasBackgroundImage() {
        return this.sidebarservice.hasBackgroundImage
    }

    logOut(): void {
        const check = this.api.logOut()
        if (check) {
            // location.reload()
            window.location.href = '/login'
        }
        // this.api.logOutSession().subscribe((resp: any) => {})
    }

    toggleSidebar() {
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState())
    }

    setStateAsActive(menu: any) {
        this.activeState = menu
        this.closeAllMenus()
    }

    setStateAsActiveSubmenu(menu: any) {
        this.activeState = menu
    }

    setCollapsed() {
        this.isCollapsed = !this.isCollapsed
        if (this.isCollapsed === false) {
            this.setTheme('default')
        } else {
            this.setTheme('dark')
        }
    }

    setTheme(themeName: string) {
        this.themeService.setTheme(themeName)
    }

    toggle(currentMenu: any) {
        if (currentMenu.type === 'dropdown') {
            this.menus.forEach((element: any) => {
                if (element === currentMenu) {
                    currentMenu.active = !currentMenu.active
                } else {
                    element.active = false
                }
            })
        }
    }

    searchKeywordChange(value: string) {
        this.searchKeyword$.next(value)
        this.menus.forEach((element: any) => {
            if (element.type === 'dropdown') {
                element.submenus.forEach((e: any) => {
                    if (e.title.toLowerCase() == value.toLowerCase() && !element.active) {
                        this.toggle(element)
                    }
                })
            }
        })
    }

    closeAllMenus() {
        this.menus.forEach((element) => {
            element.active = false
        })
    }
}
